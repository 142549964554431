<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import type { Writable } from "svelte/store";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";
	import { createEventDispatcher, getContext } from "svelte";
	import { isMenuActive } from "../../utils/isMenuActive";
	import Link from "../Link.svelte";
	import { getGlobalSettings } from "../../getGlobalSettings";
	import type { GlobalTemplateProps } from "../../GlobalTemplateProps.js";

	const { TENANT_LOCALE } = getGlobalSettings();
	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);

	const dispatch = createEventDispatcher<{
		click: MenuItem;
	}>();

	export let menuItem: MenuItem;
	export const parentSubmenuItem: MenuItem["id"] | null = null;

	export let activeSubmenu: Writable<MenuItem["id"] | null>;

	function isSubmenuActive(submenu: MenuItem["id"] | null, menuItem: MenuItem): boolean {
		if (menuItem.id === submenu) {
			return true;
		}

		return menuItem.submenus.some((submenuItem) => isSubmenuActive(submenu, submenuItem));
	}
</script>

{#if menuItem.submenus.length > 0}
	<div
		class="z-50 grid grid-rows-[0fr] transition-[grid-template-rows,opacity] duration-300 ease-in-out lg:pointer-events-none lg:absolute lg:left-1/2 lg:top-[110%] lg:block lg:-translate-x-1/2 lg:rounded-lg lg:bg-gray-300 lg:py-1 lg:opacity-0 lg:shadow-md lg:group-hover/menu:pointer-events-auto lg:group-hover/menu:opacity-100 {isSubmenuActive(
			$activeSubmenu,
			menuItem,
		) && 'grid-rows-[1fr]'}"
	>
		<ul class="flex flex-col items-center overflow-hidden">
			{#each menuItem.submenus as submenuItem}
				{@const isActive = isMenuActive(submenuItem, pageSlug[TENANT_LOCALE] ?? "")}
				<li class="group/submenu cursor-pointer lg:px-4">
					<Link
						noUnderline
						class="group-hover/submenu:text-primary text-secondary text-2xs lg:text-4xs block w-full whitespace-nowrap px-5 py-3 text-center font-medium uppercase tracking-[0.125rem] transition-colors duration-300 lg:px-0 {isActive
							? 'text-primary'
							: ''}"
						href={`/${submenuItem.href}`}
						target={submenuItem.target}
						on:click={() => {
							dispatch("click", submenuItem);
						}}
					>
						{submenuItem.name}
					</Link>
				</li>
			{/each}
		</ul>
	</div>
{/if}
