import type { Locale } from "../../core/schema/Locale.js";
import type { TenantId, TenantizedString } from "../../core/schema/Tenant.js";

export const globalSettingsContextKey = "globalSettings";

export interface GlobalSettings {
	VERSION: string;
	TENANT_ID: TenantId;
	TENANT_LOCALE: Locale;
	ENV: string;
	ENV_DOMAIN: string;
	ROBOTS: "index" | "noindex";
	STORAGE_URL: string;
	UPLOAD_URL: string;
	WEB_URL: TenantizedString;
	PUBLIC_GRAPHQL_ENDPOINT: string;
	COOKIES_EU: string;
	COOKIES_CZ: string;
	GOOGLE_ANALYTICS: string;
}
