<script lang="ts">
	import { TenantId } from "../../../core/schema/Tenant";
	import { getGlobalSettings } from "../getGlobalSettings";

	const settings = getGlobalSettings();
	const cookieIds = {
		[TenantId.cz]: "COOKIES_CZ",
		[TenantId.eu]: "COOKIES_EU",
	};
	const cookieId = cookieIds[settings.TENANT_ID] as "COOKIES_CZ" | "COOKIES_EU";

	/* eslint-disable svelte/no-at-html-tags */
</script>

{@html `<script type="text/javascript" charset="UTF-8" src="//cdn.cookie-script.com/s/${settings[cookieId]}.js"></script>`}
